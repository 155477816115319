<template>
  <div class="video-page">
     <!-- 视频 nofullscreen preload="auto"  x-webkit-airplay="allow"
            x5-video-player-type="h5"
            style="object-fit:fill"-->
           <video 
              :src="$route.query.path" 
              :poster="$route.query.poster"
              controls 
              controlslist="nodownload"
              width="100%">您的浏览器不支持video
          </video>
  </div>
</template>

<script>
import {uwStatisticAction} from "../../utils/util";

export default {
  data() {
    return{
      path:'',
      poster: require('@/assets/img/news/vrbg.png')
    }
  },
  mounted(){
    document.title = this.$route.query.title
      uwStatisticAction('/videopage','视频')
  }
}
</script>
<style lang="less" scoped>
.video-page{
  display: flex;
  align-items: center;
  height: 100%;
  background: #000;
}
</style>